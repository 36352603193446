<template>
  <div class="d-inline">
    <div v-b-modal.resetpassword class="ma-0 pa-0 d-inline">
      <slot>
        <b-button variant="link" class="link_styles">Forgot Password</b-button>
      </slot>
    </div>

    <b-modal
      id="resetpassword"
      title="Reset Password"
      ok-title="Send New Password"
      @hidden="email = null"
      @shown="email = null"
      @ok="sendForm"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group label="Email" label-align="left">
        <b-input v-model="email" required type="email"></b-input>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import AccountService from '@/services/AccountService'

export default {
  name: 'ForgotPassword',

  data () {
    return {
      email: null
    }
  },

  methods: {
    sendForm (bvModalEvt) {
      bvModalEvt.preventDefault()
      AccountService.resetPassword(this.email).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.$nextTick(() => {
            this.$bvModal.hide('resetpassword')
            this.$emit('refresh')
          })
        },
        (err) => {
          this.$aimNotify.error(err.response)
        }
      )
    }
  }
}
</script>
<style scoped>
  .link_styles:hover {
    color: rgb(180, 210, 226) !important;
  }
</style>
