<template>
  <b-form
    @submit.prevent="verify"
    ref="loginForm"
  >
    <b-form-group
      label="Email:"
      label-for="email"
      label-cols="12"
      label-cols-md="4"
      label-class="text-right login-label"
    >
      <b-form-input
        id="email"
        v-model="form.email"
        type="email"
        placeholder="Enter email"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Password:"
      label-for="passwd"
      label-cols="12"
      label-cols-md="4"
      label-class="text-right login-label"
    >
      <b-form-input
        id="passwd"
        v-model="form.password"
        type="password"
        placeholder="Enter password"
        required
      ></b-form-input>
    </b-form-group>

    <b-button
      type="submit"
      variant="primary"
      :disabled="loading || !formIsValid"
    >Log In</b-button>
  </b-form>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'Login',

  data: () => {
    return {
      loading: false,
      redirect: null,
      form: {
        email: null,
        password: null
      }
    }
  },

  mounted () {
    this.init()
  },

  watch: {
    // watch to throw an auth required error when we catch a redirect from the router
    '$route.query.redirect': function (n, o) {
      if (n !== o && n !== this.redirect) {
        if (this.$route.query && this.$route.query.redirect) {
          // If I see a ?redirect= in the URL, I'm throwing a notice
          this.$aimNotify.notify(null, 'error', 'Authorization Required', 'You must log in to access the requested resource.')
        }
      }
      this.redirect = n || null
    }
  },

  methods: {
    init () {
      if (this.$route.query && this.$route.query.redirect) {
        this.redirect = this.$route.query.redirect || null
        this.$aimNotify.notify(null, 'error', 'Authorization Required', 'You must log in to access the requested resource.')
      }
    },

    async verify () {
      if (!this.formIsValid) {
        return false
      }
      this.$updateloading(1)

      let mask = 0
      if (this.$route.query && this.$route.query.mask && this.$route.query.user_id && this.$route.query.user_id > 0) {
        mask = this.$route.query.user_id
      }

      UserService.login(this.form.email, this.form.password, this.redirect, mask).then(response => {
        this.$aimNotify.notify(response, 'info', 'Authentication Successful', 'You have successfully logged in.')
      }).catch(error => {
        setTimeout(() => {
          this.$aimNotify.error(error.response)
        }, 3500)
      }).finally(() => {
        this.$updateloading(-1)
      })
    },

    formIsValid () {
      let valid = false
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'loginForm')) {
        valid = true
        this.$refs.loginForm.inputs.some(e => {
          if (e.valid === false) {
            valid = false
            return true
          }
          return false
        })
      }
      return valid
    }
  }
}
</script>
