<template>
  <b-container class="home">
    <b-overlay :show="loading">
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <b-card class="mb-4">
            <h2 class="w-100 text-center mb-4">
              Welcome to the Streamlined Dashboard
            </h2>
            <Login></Login>
          </b-card>
          <ForgotPassword></ForgotPassword>
          <p class="small text--white">* By logging in and using this portal the client understands that Third party access may be used for the purpose of obtaining necessary data to meet agreed upon terms and
            the dashboard is for informational purposes only to help understand financial information,
            and in no way substitutes the accurate financial reports presented by SBS at year end for Tax Preparation.</p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import UserService from '@/services/UserService'
import Login from '@/components/authentication/Login.vue'
import ForgotPassword from '@/components/authentication/ForgotPassword'

export default {
  name: 'Home',
  components: {
    Login,
    ForgotPassword
  },
  data () {
    return {
      loading: true,
      user: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.user = await UserService.getUserInfo()
      if (this.user) {
        if (UserService.isAdmin()) {
          this.$router.push('/admin/onboarding')
        } else if (UserService.isBookkeeper()) {
          this.$router.push('/bookkeeping')
        } else {
          this.$router.push('/dashboard')
        }
      } else {
        this.loading = false
      }
    },

    logout () {
      UserService.logout()
    }
  }
}
</script>
