<template>
  <div class="d-inline">
    <div v-b-modal.resetpassword class="ma-0 pa-0 d-inline">
      <slot>
        <b-button variant="link" class="link_styles">Forgot Password</b-button>
      </slot>
    </div>

    <b-modal
      id="resetpassword"
      title="Reset Password"
      ok-title="Send New Password"
      :ok-disabled="isSubmitting"
      @hidden="resetForm"
      @shown="resetForm"
      @ok="sendForm"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group label="Email" label-align="left" :state="emailState" :invalid-feedback="emailFeedback">
        <b-input v-model="email" required type="email" :state="emailState"></b-input>
      </b-form-group>
      <div v-if="errorMessage" class="text-danger">{{ errorMessage }}</div>
    </b-modal>
  </div>
</template>

<script>
import AccountService from '@/services/AccountService'

export default {
  name: 'ForgotPassword',

  data () {
    return {
      email: null,
      emailState: null,
      emailFeedback: 'Please enter a valid email address',
      errorMessage: null,
      isSubmitting: false
    }
  },

  methods: {
    validateEmail () {
      if (!this.email) {
        this.emailState = false
        return false
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.emailState = emailRegex.test(this.email)
      return this.emailState
    },

    resetForm () {
      this.email = null
      this.emailState = null
      this.errorMessage = null
    },

    sendForm (bvModalEvt) {
      bvModalEvt.preventDefault()

      // Validate email before sending
      if (!this.validateEmail()) {
        return
      }

      this.errorMessage = null
      this.isSubmitting = true

      AccountService.resetPassword(this.email).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.isSubmitting = false
          this.$nextTick(() => {
            this.$bvModal.hide('resetpassword')
            this.$emit('refresh')
          })
        },
        (err) => {
          this.isSubmitting = false
          if (err.response) {
            // Check if this is our custom "email not found" error
            if (err.response.data && err.response.data.exists === false) {
              this.errorMessage = 'This email does not exist in our system.'
              this.$aimNotify.warning({ data: { message: 'Email not found in our system.' } })
            } else {
              this.$aimNotify.error(err.response)
              this.errorMessage = err.response.data?.message || 'An error occurred. Please try again.'
            }
          } else {
            this.errorMessage = 'Network error. Please check your connection and try again.'
            this.$aimNotify.error({ data: { message: this.errorMessage } })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.link_styles:hover {
  color: rgb(180, 210, 226) !important;
}
</style>
