import axios from 'axios'

const AccountService = {
  async save (data) {
    return axios({
      url: axios.defaults.baseURL + 'account',
      method: 'POST',
      data: JSON.stringify({ data: data })
    })
  },

  async resetPassword (email) {
    return axios({
      url: axios.defaults.baseURL + 'login/resetpassword/',
      method: 'POST',
      data: JSON.stringify({ email: email })
    })
  }
}

export default AccountService
